import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const lesoirdevenement = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Le soir d'événement" />
    <h3 className='underline-title'>Le soir d'événement</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Le soleil las de rengaine radiophonique<br />
        Se glisse au-delà du massif Kahuzi-Biega<br />
        Ses rayons mordorés s'irradient généreusement<br />
        Sa chaleur suave chatouille les membres croulants de mon corps<br />
        Les eucalyptus tels les saules pleureurs aux feuilles lancéolées<br />
        Au bord du lac bruissent avec tendresse mélancolique<br />
        Religieusement les cyprès de mon jardin s'immobilisent<br />
        Et transmettent leurs confidentiels messages au soleil tombant<br />
        La basse brise vespérale balafre la face endolorie du lac sournois<br />
        Les mamelons du Kahuzi-Biega barrent au loin<br /> 
        L'horizon voilé d'immenses pagnes au ton laiteux<br />
        Voici que la nuit tire son voile fuligineux<br />
        Et les étoiles font leur apparition clignotante<br />
        C'est l'heure tardive du retour des militants courroucés<br />
        Ils rentraient d'une marche mémorable<br />
        Marche de protestation et de soutien<br />
        Authentique marche de soutien celle-là<br />
        C'était le soir de la stupeur profonde<br />
        Stupeur diffusée par le <span className="black-bold">grand-tam-tam</span>
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 20 juin 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default lesoirdevenement
